

import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import 'bootstrap/dist/css/bootstrap.min.css';
import reportWebVitals from './reportWebVitals';
// import { WagmiConfig, configureChains, createConfig } from 'wagmi';
// import { EthereumClient, w3mConnectors, w3mProvider } from '@web3modal/ethereum';
// import { Web3Modal } from '@web3modal/react';
// import {bsc, bscTestnet } from 'wagmi/chains';
import { BrowserRouter } from 'react-router-dom'
import store, { persistor } from "./redux/storeConfig.js";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import '../src/assets/style/DataTable.css'



// const chains = [bscTestnet]
// const projectId = "6b098530af4797b4b0dcb37e0534845a";
// const { publicClient } = configureChains(chains, [w3mProvider({ projectId })])

// const wagmiConfig = createConfig({
//   autoConnect: true,
//   connectors: w3mConnectors({ projectId, chains }),
//   publicClient

// })

// const ethereumClient = new EthereumClient(wagmiConfig, chains)

// const root = ReactDOM.createRoot(document.getElementById('root'));
// root.render(
//   <>
//     <WagmiConfig config={wagmiConfig}>
//       <Provider store={store}>
//         <PersistGate loading={null} persistor={persistor}>
//           <BrowserRouter>
//             <App />
//           </BrowserRouter>
//         </PersistGate>
//       </Provider>
//     </WagmiConfig>
//     <Web3Modal themeMode='dark' themeVariables={{ "--w3m-z-index": '99999' }} projectId={projectId} ethereumClient={ethereumClient} />
//   </>
// );

// // If you want to start measuring performance in your app, pass a function
// // to log results (for example: reportWebVitals(console.log))
// // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();






// const chains = [bscTestnet]
// const projectId = "7cb46dad9b7e8c69ea65c450b55fe035";
// const { publicClient } = configureChains(chains, [w3mProvider({ projectId })])

// const wagmiConfig = createConfig({
//   autoConnect: true,
//   connectors: w3mConnectors({ projectId, chains }),
//   publicClient
// })

// const ethereumClient = new EthereumClient(wagmiConfig, chains)




// ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
import { createWeb3Modal } from '@web3modal/wagmi/react'
import { http, createConfig, WagmiProvider } from 'wagmi'
import { mainnet, bscTestnet, bsc } from 'wagmi/chains'
import { walletConnect, injected, coinbaseWallet, metaMask } from 'wagmi/connectors'

import { QueryClient, QueryClientProvider } from '@tanstack/react-query'

// 0. Setup queryClient
const queryClient = new QueryClient()

// 1. Get projectId at https://cloud.walletconnect.com
const projectId = '8c38600d1d3d9982780dc03db558a7e1'

// 2. Create wagmiConfig
const metadata = {
  name: 'Web3Modal',
  description: 'Presale Sportsmint',
  url: 'https://presale.sportsmint.io', // origin must match your domain & subdomain
  icons: ['https://avatars.githubusercontent.com/u/37784886']
}

const config = createConfig({
  chains: [bsc],
  //chains: [bscTestnet],
  transports: {
    [bsc.id]: http('https://bsc-dataseed1.binance.org'),
    //[bscTestnet.id]: http()
  },
  connectors: [
    // metaMask({
    //   useDeeplink:true,
    //   openDeeplink:"https://metamask.app.link/dapp/presale.sportsmint.io/ourClub",
    // }),
    walletConnect({ projectId, metadata, showQrModal: false }),
    injected({ shimDisconnect: true }),
    coinbaseWallet({
      appName: metadata.name,
      appLogoUrl: metadata.icons[0]
    }),
  ]
})

// 3. Create modal
createWeb3Modal({
  wagmiConfig: config,
  projectId,
  enableAnalytics: true, // Optional - defaults to your Cloud configuration
  enableOnramp: true, // Optional - false as default
  defaultChain: bsc,
  // customWallets: [{

  //   id: "c57ca95b47569778a828d19178114f4db188b89b763c899ba0be274e97267d96",
  //   name: "Metamask",
  //   homepage: "https://presale.sportsmint.io",
  //   image_id: "5195e9db-94d8-4579-6f11-ef553be95100",
  //   image_url: "https://explorer-api.walletconnect.com/v3/logo/lg/5195e9db-94d8-4579-6f11-ef553be95100?projectId=2f05ae7f1116030fde2d36508f472bfb",
  //   // order?: number;
  //   mobile_link: "https://metamask.app.link/dapp/presale.sportsmint.io/ourClub",
  //   // desktop_link?: string | null;
  //   webapp_link: "https://metamask.app.link/dapp/presale.sportsmint.io/ourClub",
  //   // app_store?: string | null;
  //   // play_store?: string | null;
  //   // chrome_store?: string | null;
  //   // rdns?: string | null;
  //   // injected?: {
  //   //     namespace?: string;
  //   //     injected_id?: string;
  //   // }[] | null;
  // }]
})

// +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <>
    <WagmiProvider config={config}>

      <QueryClientProvider client={queryClient}>
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <BrowserRouter>
              <App />
            </BrowserRouter>
          </PersistGate>
        </Provider>
      </QueryClientProvider>
    </WagmiProvider>

    {/* <Web3Modal themeMode='dark'  themeVariables={{ "--w3m-z-index": '99999' }} projectId={projectId}  /> */}
  </>

);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();