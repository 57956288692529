import React, { useEffect, useState } from "react";
import "../../../src/assets/style/MyReferral.css";
import { MdContentCopy } from "react-icons/md";
import { IoShareSocialOutline } from "react-icons/io5";
import DataTable from "react-data-table-component";
import Header from "../layout/header/Header";
import Modal from "react-bootstrap/Modal";
import ListTable from "../../Helper/DataTable";
import {
  getuserreferralAction,
  getusertransferablebalanceAction,
  getuserreferralsearningAction,
  getTranferHistory,
  transferReferral,
} from "../../Action/user.action";
import toast, { Toaster } from "react-hot-toast";
import Cookies from "js-cookie";
import Footer from "../layout/footer/Footer";
import config from "../../config/config";
import { useNavigate } from "react-router-dom";
import SidebarPro from "./sidebar/Sidebar";
import DashboardHeader from "./header/Header";
import { useSelector } from "react-redux";
import { Col, Row } from "react-bootstrap";

export default function MyReferral(props) {
  const AfterConnectAddress = useSelector(
    (state) => state.auth.walletConnnected
  );
  const [isMobile, setIsMobile] = useState(false);

  const [referralhistory, setreferralhistory] = useState([]);
  const [referralTransferHistory, setReferalTransferHistory] = useState([]);
  const loginData = useSelector((state) => state.auth.LoginDetails); // (!Cookies.get('loginSuccessWeb3Sports')) ? [] : JSON.parse(Cookies.get('loginSuccessWeb3Sports'));
  const [form, setForm] = useState({ friendAddress: "", amount: "" });
  const [error, setError] = useState("");
  const [transferablebalance, settransferablebalance] = useState(0);
  const [holdbalance, setholdbalance] = useState("0.0000");
  const [referablebalance, setreferablebalance] = useState(0);
  const [referralBalance, setreferralBalance] = useState();
  const [userreferralsearning, setuserreferralsearning] = useState();

  const navigate = useNavigate();

  useEffect(() => {
    console.log(window.innerWidth);
    if (window.innerWidth <= 768) {
      setIsMobile(true);
    }
  }, [window.innerWidth])

  useEffect(() => {
    getreferralhistoryfunc();
    getreferralTransferHistory();
    return () => { };
  }, []);

  const getreferralhistoryfunc = async () => {
    try {
      let res = await getuserreferralAction({
        token: loginData.authToken,
      });
      setreferralBalance(res.data.data.get_my_referral);
      setuserreferralsearning(res.data.data.friends_earning);
      settransferablebalance(res.data.data.transferable_balance);
      setholdbalance(res.data.data.hold_balance);
      if (res.data.success) {
        setreferralhistory(res.data.data.referralHistory);
      } else {
        setreferralhistory([]);
      }
    } catch (err) {
      setreferralhistory([]);
      setreferralBalance(0);
      setuserreferralsearning(0);
      settransferablebalance(0);
      setholdbalance(0);
      console.log(err);
    }
  };

  const getreferralTransferHistory = async () => {
    try {
      let res = await getTranferHistory({
        token: loginData.authToken,
      });

      setreferablebalance(res.data.data.referral_balance);
      if (res.data.success) {
        setReferalTransferHistory(res.data.data.history);
      } else {
        setReferalTransferHistory([]);
      }
    } catch (err) {
      setreferablebalance(0);
      setReferalTransferHistory([]);
      console.log(err);
    }
  };

  const inputHandler = async (e) => {
    const { name, value } = e.target;
    const addressRegex = /^(0x)?[0-9a-fA-F]{40}$/;
    const amountRegex = /^\d+(\.\d{1,2})?$/;
    if (name === "friendAddress") {
      if (!addressRegex.test(value)) {
        setError("Invalid wallet address");
        setForm((old) => ({
          ...old,
          friendAddress: value,
          isValidAddress: false,
        }));
      } else {
        setForm((old) => ({
          ...old,
          friendAddress: value,
          isValidAddress: true,
        }));
        setError("");
      }
    } else if (name === "amount") {
      const decimalCount = (value.match(/\./g) || []).length; // Count number of decimal points
      const decimalDigits = value.split(".")[1]; // Get digits after the decimal point

      if (decimalCount > 1) {
        setError(
          "Invalid amount. Please enter a valid number with only one decimal point."
        );
        return;
      } else if (decimalDigits && decimalDigits.length > 2) {
        setError(
          "Invalid amount. Please enter a valid number with up to two decimal places."
        );
        return;
      } else {
        setForm((old) => ({
          ...old,
          amount: value,
          isValidAmount: true,
        }));
        setError("");
      }
    } else {
      // If input field is not for wallet address, update the form state as usual
      setForm((old) => ({ ...old, [name]: value }));
    }
  };

  // -------------popup modal ---------------------
  const { collapsed, setCollapsed } = props;

  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);

  const handleClose = async () => {
    setForm({});
    setShow(false);
    // setIsCheckboxChecked(false)
  };
  // -------------popup modal ----------------------

  const TransferReferal = async (e) => {
    e.preventDefault();

    if (form.friendAddress == "" || form.amount == "") {
      toast.error("Please fill the required fields!");
      return;
    } else {
      if (form.isValidAddress) {
        if (parseFloat(form.amount) > parseFloat(transferablebalance)) {
          toast.error("Insufficient MNT!");
          return;
        }
        if (form.friendAddress == loginData.wallet_address) {
          toast.error("You cannot transfer to your own wallet!");
          return;
        }
        if (form.amount > 0) {
          let res = await transferReferral({
            token: loginData.authToken,
            friendAddress: form.friendAddress,
            amount: parseFloat(form.amount),
          });
          if (res.data.success) {
            toast.success(res.data.msg);
            getreferralhistoryfunc();
            getreferralTransferHistory();
            setForm("");
            handleClose();
          } else {
            toast.error(res.data.msg);
            handleClose();
            setForm("");
          }
        } else {
          toast.error("Amount should be greater than 0!");
        }
      } else {
        toast.error("Please fill the required fields!");
      }
    }
  };

  return (
    <>
      <Toaster />
      <div className="dashboard" style={{ marginBottom: isMobile ? '100px' : '' }}>
        <SidebarPro collapsed={collapsed} setCollapsed={setCollapsed} />
        <div className={collapsed ? "marginL25" : "marginL250"} id="outlet">
          <DashboardHeader
            className={collapsed ? "marginL25" : "marginL250"}
            collapsed={collapsed}
            setCollapsed={setCollapsed}
          />
          <div className="container">
            <div className="my_referral my-5">
              <div className="row mx-0">
                <div className="col-lg-6">
                  <div className="pe-0 pe-xl-2">
                    <h4>Referral</h4>

                    <div className="row ref">
                      <div className="col-md-6 p-0 px-md-2 ref_child pb-2 pb-md-0">
                        Friends Earning :{" "}
                        <b>{parseFloat(userreferralsearning).toFixed(2)} MNT</b>{" "}
                      </div>
                      <div className="col-md-6 p-0 px-md-2 ps-2 mt-2 mt-md-0">
                        My Earning :{" "}
                        <b>{parseFloat(referralBalance).toFixed(2)} MNT</b>{" "}
                      </div>
                    </div>

                    <div className="position-relative mt-3">
                      <label htmlFor="">Invitation Link</label>
                      <input
                        type="url"
                        className="form-control"
                        placeholder="https//www.sportmint.io...ref =frankin jr"
                        value={`${config.serverreferalUrl}${loginData.wallet_address}`}
                      />
                    </div>

                    <div className="my-3">
                      <div className="row">
                        <div className="col-lg-6">
                          <button
                            className="btn_uni w-100"
                            onClick={() => {
                              navigator.clipboard
                                .writeText(
                                  `${config.serverreferalUrl}${loginData.wallet_address}`
                                )
                                .then(() => {
                                  toast.success("Referral Link Copied");
                                });
                            }}
                          >
                            Referral Link
                            <span>
                              <MdContentCopy />
                            </span>
                          </button>
                        </div>
                        {/* <div className="col-lg-6 mt-3 mt-lg-0">
                          <button
                            onClick={() => handleShow()}
                            className="btn_uni w-100"
                          >
                            TRANSFER MNT
                          </button>
                        </div> */}
                      </div>
                    </div>

                    <div className="row ref">
                      <div>
                        <div className="mb-0">
                          Referral Balance : <b>{referablebalance} MNT</b>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 mt-4 mt-md-0 position-relative">
                  {/* <span className="address_btn position-absolute">Add:121</span> */}
                  <div>
                    <img src="myref.png" className="img-fluid" alt="" />
                  </div>

                  <h5>Refer and Earn MNT Tokens</h5>

                  <p className="ref_p">
                    SportsMint has a refer-and-earn program through which users
                    can earn a 5% commission on the package bought by their
                    referee. Whenever they buy a package, the referral
                    commission is adjusted in your dashboard in the "Referral
                    Earning" section.
                  </p>
                </div>
              </div>
            </div>

            <ul class="nav nav-tabs my-3" id="myTab" role="tablist">
              <Row>
                <Col>
                  <li className="nav-item me-3 w-100" role="presentation">
                    <button
                      className="nav-link active w-100"
                      id="home-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#home-tab-pane"
                      type="button"
                      role="tab"
                      aria-controls="home-tab-pane"
                      aria-selected="true"
                    >
                      Referral History
                    </button>
                  </li>
                </Col>
              </Row>

              {/* <li class="nav-item" role="presentation">
                <button
                  class="nav-link"
                  id="profile-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#profile-tab-pane"
                  type="button"
                  role="tab"
                  aria-controls="profile-tab-pane"
                  aria-selected="false"
                >
                  Transfer History
                </button>
              </li> */}
            </ul>
            <div class="tab-content" id="myTabContent">
              <div
                class="tab-pane fade show active"
                id="home-tab-pane"
                role="tabpanel"
                aria-labelledby="home-tab"
                tabindex="0"
              >
                {referralhistory && referralhistory.length ? (
                  <ListTable DataList={referralhistory} type={1} />
                ) : (
                  <div className="h-75">
                    <div className="text-center">
                      <img
                        src="ds/nodatapreview.png"
                        alt=""
                        className="img-fluid"
                        style={{ width: "200px" }}
                      />
                    </div>
                    <h4 className="mt-3 text-center">No Data Found</h4>
                  </div>
                )}
              </div>
              <div
                class="tab-pane fade"
                id="profile-tab-pane"
                role="tabpanel"
                aria-labelledby="profile-tab"
                tabindex="0"
              >
                {referralTransferHistory && referralTransferHistory.length ? (
                  <ListTable DataList={referralTransferHistory} type={0} />
                ) : (
                  <div className="container h-100 mt-5">
                    <div className="h-75">
                      <div className="text-center">
                        <img
                          src="ds/nodatapreview.png"
                          alt=""
                          className="img-fluid"
                          style={{ width: "200px" }}
                        />
                      </div>
                      <h4 className="mt-3 text-center">No Data Found</h4>
                    </div>
                  </div>
                )}
              </div>
            </div>

            {/* <Footer /> */}
          </div>
        </div>
      </div>

      {/* ==============Modal popup============== */}
      <Modal show={show} onHide={handleClose} className="modal">
        <Modal.Header closeButton></Modal.Header>
        <h4 className="text-center my-3">
          Transfer MNT to Internal Registered Wallet
        </h4>
        <div className="">
          <form>
            <input
              type="text"
              className="form-control my-3"
              placeholder="Enter MNT Amount"
              onChange={inputHandler}
              onPaste={(e) => {
                // Prevent paste action
                e.preventDefault();
              }}
              onKeyPress={(event) => {
                if (!/^\d*[.]?\d{0,1}$/.test(event.key)) {
                  event.preventDefault();
                }
              }}
              name="amount"
              value={form.amount}
            />
            {holdbalance == "0.0000" ? (
              ""
            ) : (
              <p>Holding Balance : {holdbalance}</p>
            )}
            <p>Transferable Balance : {transferablebalance} </p>
            <input
              type="text"
              className="form-control my-3"
              placeholder="Enter Wallet Address"
              onChange={inputHandler}
              name="friendAddress"
              value={form.friendAddress}
            />
            <div className="text-center">
              <button
                style={{ padding: "4px 0px" }}
                className="btn_uni px-3"
                onClick={TransferReferal}
              >
                TRANSFER
              </button>
            </div>
          </form>
        </div>
      </Modal>
      {/* ==============Modal popup============== */}
    </>
  );
}
