import React from "react";
import '../../../assets/style/HomeBanner.css'
import { FaArrowRightLong } from "react-icons/fa6";
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';

// import './styles.css';

import { EffectCoverflow } from 'swiper/modules';
import { Autoplay } from "swiper/modules";
import { Bounce } from "react-awesome-reveal";
import toast, { Toaster } from "react-hot-toast";
import ToastPopup from "../../Toast/ToastPopup";

// import required modules


export default function HomeBanner() {
    return (
        <>
            {/* <ToastPopup /> */}
            {/* <Toaster /> */}
            <section className="home_banner">
                <div className="banner_mask"></div>
                <div className="container">
                    <div className="banner_content">
                        <div className="row">
                            <div className="col-lg-6 d-flex align-items-center justify-content-center">
                                <div>
                                
                                        <div>
                                            <h1 className="text-center">Next Generation Web 3.0 Fantasy Sports Gaming Platform!</h1>

                                            {/* <h1>Fantasy Sports Gaming Platform!</h1> */}

                                            <p className="text-center">Experience Decentralized <br /> Fantasy Cricket and NFT Thrills</p>

                                            <div className="text-white why_presale text-center p-0">
                                                <span className="heading_b px-5">Presale is Live Now!</span>
                                            </div>

                                            {/* <div className="mt-2">
                                                <button className="btn banner_btn position-relative d-flex align-items-center">
                                                    <span className="me-2">SIGN UP TODAY!</span>
                                                    <span className="d-flex alighn-items-center"><FaArrowRightLong /></span>
                                                </button>
                                            </div> */}
                                        </div>
                                
                                </div>
                            </div>
                            <div className="col-lg-6 text-center mt-3 mt-lg-0">
                                <Swiper
                                    effect={'coverflow'}
                                    slidesPerView={3}
                                    spaceBetween={1}
                                    grabCursor={true}
                                    loop={true}
                                    autoplay={{
                                        delay: 2000,
                                        disableOnInteraction: false
                                    }}
                                    centeredSlides={true}
                                    // slidesPerView={'auto'}
                                    coverflowEffect={{
                                        rotate: 50,
                                        stretch: 0,
                                        depth: 100,
                                        modifier: 1,
                                        slideShadows: true,
                                    }}
                                    modules={[EffectCoverflow, Autoplay]}
                                    className="mySwiper"
                                >
                                    <SwiperSlide>
                                        <img src="Banner_NFT/nicholaspooran.png" alt="" className="img-fluid" width={600} />
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <img src="Banner_NFT/ravibishnoi.png" alt="" className="img-fluid" width={600} />
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <img src="Banner_NFT/shadabkhan.png" alt="" className="img-fluid" width={600} />
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <img src="Banner_NFT/shubhmangill.png" alt="" className="img-fluid" width={600} />
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <img src="Banner_NFT/sikandarraza.png" alt="" className="img-fluid" width={600} />
                                    </SwiperSlide>
                                </Swiper>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <div className="mask"></div> */}
                {/* <div className="container">
                    <div className="row">
                        <div className="col-6">
                            <div className="content">
                                Lorem, ipsum dolor sit amet consectetur adipisicing elit. Expedita incidunt pariatur praesentium debitis? Eius vel placeat obcaecati harum, voluptates deleniti commodi ratione doloribus, atque dignissimos officiis blanditiis. Voluptate ratione, necessitatibus rerum eius cupiditate sit quos reiciendis harum veritatis adipisci, expedita dolorem quaerat corporis possimus maxime aliquid? Esse minus eaque laudantium ipsum repellat soluta optio fugiat labore repellendus ex beatae, modi velit necessitatibus enim cumque accusantium nostrum eius consequatur dicta aliquam. Molestias eos perferendis fugit labore. Soluta aut dolorem perspiciatis voluptatem reprehenderit dolorum illum consectetur odio id possimus, nulla quia, facilis ducimus fugit enim maxime voluptate harum cum consequatur? Quas, beatae!
                            </div>
                            <div className="col-6"></div>
                        </div>
                    </div>
                </div> */}
            </section>
        </>
    )
}