import { getRequest, postRequest, putRequest, deleteRequest, postRequestFormData } from "../directives/helper";
import axiosClient from "../config/axiosConfig";

export const loginAction = (data) => {
  return postRequest('user/loginwithaddress', data).then(res => { return res.data })
}

export const loginActionWithHash = (data) => {
  return postRequest('user/loginwithhash', data).then(res => { return res.data });
}

export const generateotpforemailAction = (data) => {
  
  
  return postRequest('user/generateotpforemail', data).then(res => {
    return res.data })
}

export const registrationAction = (data) => {
  return postRequest('user/registeruser', data).then(res => {
    return res.data })
}



//  xport const displayNftsAction = () => {
//   return axiosClient("user/displayNfts" ,  "GET", { });
// };e

export const displayNftsAction = () => {
  return getRequest('user/displayNfts').then(res => { return res.data })
}

export const getfaqAction = () => {
  return getRequest('user/getfaq').then(res => { return res.data })
}

export const displayfilterparameterAction = () => {
  return getRequest('user/displayfilterparameter').then(res => { return res.data })
}

export const displayCountriesAction = () => {
  return getRequest('user/displayfilterparameter').then(res => { return res.data })
}

export const displayNftsFilterAction = (data) => {
  return getRequest(`user/displayNfts?skill_id=${data}`).then(res => { return res.data })
}

// export const getplanpurchaseconfirmationstatusAction = (data) => {
//   return getRequest(`user/getplanpurchaseconfirmationstatus?packageId=${data}`).then(res => { return res.data })
// }

export const getplanpurchaseconfirmationstatusAction = (data) => {
  return axiosClient(`user/getplanpurchaseconfirmationstatus?packageId=${data.data}` ,"", "GET", {
    Authorization: data.token,
  });
};

export const autopickplanforuserAction = (data) => {
  return axiosClient(`user/autopickplanforuser?packageId=${data.data}` ,"", "GET", {
    Authorization: data.token,
  });
};

export const displayNftsFilterCountryAction = (data,data1,data2) => {
  return getRequest(`user/displayNfts?skill_id=${data}&country_id=${data1}&tournament_id=${data2}`).then(res => { return res.data })
}

export const displayNftsFilterSkillAction = (data) => {
  return getRequest(`user/displayNfts?skill_id=${data}`).then(res => { return res.data })
}


export const getPackages = (data) => {
  return getRequest('user/getnftpackages', data).then(res => { return res.data })
}

export const getUserProfile = (token) => {
  return axiosClient("user/getuserprofile", "", "GET", {
    Authorization: token,
  });
};


 export const getUserClubs = (token) => {
  return axiosClient("user/getmyclubdetails" ,"", "GET", {
    Authorization: token,
  });
};

export const displayUserAllNfts = (token) => {
  return axiosClient("user/getuserallnft" ,"", "GET", {
    Authorization: token,
  });
};

export const getuserbonusandpromonft = (token) => {
  return axiosClient("user/getuserbonusandpromonft" ,"", "GET", {
    Authorization: token,
  });
};

 export const updateUserProfile = (payload, token) => {
  return axiosClient("user/updateuserprofile", payload, "PUT", {
    Authorization: token,
  });
};

export const generateOtp = (payload) => {
  return axiosClient("user/generateotpforemail", payload, "POST", {
    Authorization: payload.authToken,
  });
};

export const claimpromocodeAction = (payload) => {
  return axiosClient("user/claimpromocode", payload, "POST", {
    Authorization: payload.authToken,
  });
};

export const getnftofanyuserpackageAction = (payload) => {
  return axiosClient("user/getnftofanyuserpackage", payload, "POST", {
    Authorization: payload.authToken,
  });
};


export const verifyOtp = (payload) => {
  return axiosClient("user/verifyuserotpforemail", payload, "POST", {
    Authorization: payload.authToken,
  });
};


export const getSelectedPackage = (payload) => {
  return axiosClient("user/getselectedpackagedetail", payload, "POST", {
  });
};



export const getReferalBalance = (payload) => {
  return axiosClient("user/getuseablerefferalbalance", payload, "GET", {
    Authorization: payload.token,
  });
};

export const getDashboardHistory = (payload) => {
  return axiosClient("user/getdashboarddetails", "", "GET", {
    Authorization: payload.token,
  });
};


export const getTranferHistory = (payload) => {
  return axiosClient("user/getusertransferedrefferalhistory", "", "GET", {
    Authorization: payload.token,
  });
};

export const initiateplanpurchasetrxMNT = (payload) => {
  return axiosClient("user/initiateplanpurchasetrxMNT", payload, "POST", {
    Authorization: payload.token,
  });
};



export const transferReferral = (payload) => {
  return axiosClient("user/transferrefferaltofriend", payload, "POST", {
    Authorization: payload.token,
    friendAddress:payload.friendAddress,
    amount:payload.amount

  });
};


export const initiateplanpurchasetrxUSDT = (payload) => {
  return axiosClient("user/initiateplanpurchasetrxUSDT", payload, "POST", {
    Authorization: payload.token,
  })
}

export const getuserreferralAction = (payload) => {
  return axiosClient("user/getuserreferral", "", "GET", {
    Authorization: payload.token,
  });
};


export const getRoyaltyLeaderboardListAction = () => {
  return getRequest('user/getRoyaltyLeaderboardList').then(res => { return res.data })
}


// export const gettransactionHostory = (payload) => {
//   return axiosClient("user/getplanpurchasehistory" , payload, "GET", {
//   Authorization: payload.token,
//   });
// };

export const getuserreferralbalanceAction = (payload) => {
  return axiosClient("user/getuserreferralbalance", payload, "GET", {
    Authorization: payload.token,
  });
};

export const getuserreferralsearningAction = (token, payload) => {
  return axiosClient("user/getuserreferralsearning", payload, "GET", {
    Authorization: token,
  });
};

export const getpurchasedpurchasedstatisticsAction = (token, payload) => {
  return axiosClient("user/getpurchasedpurchasedstatistics", payload, "GET", {
    Authorization: token,
  });
};

export const getplayerbypackageplanidAction = (token, payload) => {
  return axiosClient("user/getplayerbypackageplanid", payload, "POST", {
    Authorization: token,
  });
};

export const createpackagefromselectednftAction = (token, payload) => {
  return axiosClient("user/createpackagefromselectednft", payload, "POST", {
    Authorization: token,
  });
};
