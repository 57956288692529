import React from "react";
import { MdDone } from "react-icons/md";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { FaArrowRightLong } from "react-icons/fa6";
import DataTable from "react-data-table-component";
import DashboardHeader from "./header/Header";
import { getUserClubs } from "../../Action/user.action";
import { useEffect, useState } from "react";
import toast, { Toaster } from "react-hot-toast";
import config from "../../config/config";
import Header from "../layout/header/Header";
import { useDisconnect, useAccount, useBalance, useSignMessage } from "wagmi";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { IoIosAddCircleOutline } from "react-icons/io";


import {
  Container,
  Row,
  Col,
  Nav,
  Navbar,
  Form,
  Button,
  Table,
  InputGroup,
  Modal,
  Card,
} from "react-bootstrap";
import SidebarPro from "../dashboard/sidebar/Sidebar";

export default function MyClub(props) {
  const loginData = useSelector((state) => state.auth.LoginDetails);
  const [ClubData, setClubData] = useState([]);
  const [packageData, setPackageData] = useState([]);
  const navigate = useNavigate();

  const amount = <div></div>;
  const columns = [
    {
      name: "S.No",
      selector: (row, index) => index + 1,
      center: true,
      // width: "10%",
    },

    {
      name: "Club Id",
      selector: (row) => row.id,
      center: true,
      width: "auto",
    },

    {
      name: "Transactions ID",
      cell: (row) =>
        row.trx_hash ? (
          <a target="_blank" href={`${config.testneturl}${row.trx_hash}`}>
            {row.trx_hash.slice(0, 5)}...{row.trx_hash.slice(-5)}
          </a>
        ) : (
          ""
        ),
      center: true,
    },
    {
      name: "Amount",
      selector: (row) => (
        <button
          type="button"
          className="btn btn-default text-white"
          data-bs-toggle="tooltip"
          data-bs-placement="top"
          title={`Wallet Amount : ${row.paid_amount} ${row.symbol}/ Referral Amount : ${row.virtual_amount} MNT`}
        >
          {`$${parseFloat(row.priceinusdt)}`} <span></span>
        </button>
      ),


      center: true,
    },

    // `${row.paid_amount} ${row.symbol}`

    {
      name: "Purchased Date",
      selector: (row) => row.timestamp,
      center: true,
    },
  ];

  const data = [
    {
      id: 1,
      Date: "07/02/24",
      Transactions: "0001225488",
      Amount: "$500.00",
      Status: <button className="btn btn-success">Completed</button>,
    },
    {
      id: 2,
      Date: "07/02/24",
      Transactions: "0001225488",
      Amount: "$500.00",
      Status: <button className="btn btn-success">Completed</button>,
    },
    {
      id: 3,
      Date: "07/02/24",
      Transactions: "0001225488",
      Amount: "$500.00",
      Status: <button className="btn btn-success">Completed</button>,
    },
  ];

  const tableCustomStyles = {
    headRow: {
      style: {
        color: "rgb(219, 219, 219)",
        background: "rgba(34, 38, 48, 1)",
        fontSize: "18px",
      },
    },
    rows: {
      style: {
        color: "rgb(219, 219, 219)",
        backgroundColor: "rgba(23, 25, 28, 1)",
        fontSize: "16px",
      },
    },
    pagination: {
      style: {
        color: "white !important",
        backgroundColor: "transparent !important",
      }
    },
  };

  const navigatetopicker = (item) => {
    window.location.href = `${config.baseUrl}nfts/${item}`
    //     navigate(`${config.baseUrl}nfts`, {
    //   state: {
    //     packagePurchasedId: item,
    //   },
    // });
  };

  const navigatetopackagenft = (item) => {
    navigate(`${config.baseUrl}packagenft`, {
      state: {
        packagePurchasedId: item,
      },
    });
  };

  const navigatetomyclub = (item) => {
    navigate(`${config.baseUrl}ourClub`);
  };

  useEffect(() => {
    getPackage(loginData.authToken);
  }, [loginData.authToken]);

  const getPackage = async (loginData) => {
    try {
      let res = await getUserClubs(loginData);
      if (res.data.success) {
        setClubData(res?.data?.data?.packageList);
        setPackageData(res?.data?.data?.history);
      } else {
        // toast.error("Something went wrong please try again")
      }
    } catch (error) {
      console.log(error);
    }
  };

  const { collapsed, setCollapsed } = props;

  // match height code start

  const HeightMatch = () => {
    const hMtch = document.getElementsByClassName("h_match_myclub");
    const ary = [];
    for (let i = 0; i < hMtch.length; i++) {
      ary.push(hMtch[i].offsetHeight);
    }
    let max = Math.max(...ary);

    for (let i = 0; i < hMtch.length; i++) {
      hMtch[i].style.height = max + "px";
    }
  };

  useEffect(() => {
    return HeightMatch();
  }, [ClubData.length ? true : false]);

  // match height code start

  const obj = [
    "Icons/Starter.svg",
    "Icons/Essential.svg",
    "Icons/Economy.svg",
    "Icons/Standard.svg",
    "Icons/Premium.svg",
    "Icons/Deluxe.svg",
    "Icons/Ultimate.svg",
    "Icons/luxury.svg",
  ];
  return (
    <>
      <div className="dashboard">
        <SidebarPro collapsed={collapsed} setCollapsed={setCollapsed} />
        <div id="outlet" className={collapsed ? "marginL25" : "marginL250"}>
          <DashboardHeader
            className={collapsed ? "marginL25" : "marginL250"}
            collapsed={collapsed}
            setCollapsed={setCollapsed}
          />
          {ClubData && ClubData.length ? (
            <div className="container">
              <div className="our_packages">
                <div className="row mx-0">
                  {ClubData &&
                    ClubData.length > 0 &&
                    ClubData.map((item, index) => {
                      return (
                        <div key={index} className="col-xl-3 col-lg-4 mt-4">
                          <div className="card_box">
                            <div className="">
                              <h5>
                                <h5 className="d-flex justify-content-between our_pack_head">
                                  <span className="lear">{item?.name}</span>

                                  <span>
                                    <img
                                      src={item?.logo}
                                      alt=""
                                      className="img-fluid"
                                      width={30}
                                    />
                                  </span>
                                </h5>
                              </h5>
                              <p className="fw-bold">
                                Club Id - {item.package_purchased_id}
                              </p>
                            </div>
                            <div className="h_match">
                              {/* <span className="icon"><MdDone /></span> */}
                              <div
                                className="h_match mb-2 h_match_myclub"
                                dangerouslySetInnerHTML={{
                                  __html: item.short_description,
                                }}
                                style={{ color: "white" }}
                              ></div>
                            </div>
                            {/* <div style={{ color: "rgba(215, 215, 215, 1)" }}>Validity - 1 year after launch </div> */}

                            <div className="card_footer mt-2">
                              <div className="row">
                                <div className="col-lg-12 col-xl-7 my-2 my-lg-0 btn_w order-2 order-xl-1">
                                  {item.package_created ? (
                                    <Button
                                      onClick={() =>
                                        navigatetopackagenft(
                                          item.package_purchased_id
                                        )
                                      }
                                      className="btn_uni m-0"
                                    >
                                      View Nft
                                    </Button>
                                  ) : (
                                    <Button
                                      onClick={() =>
                                        navigatetopicker(
                                          item.package_purchased_id
                                        )
                                      }
                                      className="btn_uni m-0"
                                    >
                                      Checkout Now
                                    </Button>
                                  )}
                                </div>
                                <div className="col-lg-12 col-xl-5 mt-lg-2 order-1 order-xl-2">
                                  <h4>${item.price.split(".")[0]}</h4>
                                  {/* <p className="text-center">Get 3 <span className="text-info">Premium</span> NFTs</p> */}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  <div className="col-xl-3 col-lg-4 mt-4">
                    <Link to={`${config.baseUrl}ourClub`}>
                      <div className="card_box d-flex align-items-center justify-content-center">
                        <div>
                          <div className="text-center">
                            <span className="d-flex" style={{ color: '#c5c5c5', fontSize: '106px' }}>
                              <IoIosAddCircleOutline />
                            </span>
                          </div>

                          <div className="card_footer mt-2">
                            <div className="row">
                              <div className="col-lg-12 col-xl-7 my-2 my-lg-0 btn_w order-2 order-xl-1 w-100 justify-content-center">
                                <h5 className="">
                                  Add Club
                                </h5>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </div>
                </div>
              </div>

              <div className="datatable">
                <DataTable
                  title="Transactions History"
                  columns={columns}
                  data={packageData}
                  pagination
                  customStyles={tableCustomStyles}
                />
              </div>
            </div>
          ) : (
            <div className="container mt-5 h-100">
              <div className="d-flex justify-content-center align-items-center h-75">
                <div>
                  <h4 className="text-center">
                    You haven't purchase any Club yet. Please purchase one from our
                    club.
                  </h4>

                  <div className="text-center mt-4">
                    <Link to="/ourClub" className="btn_uni">
                      Buy Now
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
}
