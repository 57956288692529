import React from "react";
import { MdDone } from "react-icons/md";
import { FaArrowRightLong } from "react-icons/fa6";
import { getPackages } from "../../../Action/user.action";
import { useEffect, useState } from "react";
import Cookies from "js-cookie";
import config from '../../../config/config';
import { Link, useLocation, useNavigate } from "react-router-dom";
import Header from "../../layout/header/Header";
import { Zoom, Bounce } from 'react-awesome-reveal'


export default function OurPackages() {
    const [listPackage, setListPackage] = useState([]);
    const loginData = (!Cookies.get('loginSuccessWeb3Sports')) ? [] : JSON.parse(Cookies.get('loginSuccessWeb3Sports'));

    useEffect(() => {
        myPackages()
    }, []);

    const myPackages = async () => {
        try {
            let res = await getPackages({});
            console.log("res?.data", res?.data)
            setListPackage(res?.data)
        } catch (error) {
            console.log(error);
        }
    }

    // match height code start
    const HeightMatch = () => {
        const hMtch = document.getElementsByClassName('h_match')
        const hmatch2 = document.getElementsByClassName('h_match2')

        const ary = []
        for (let i = 0; i < hMtch.length; i++) {
            ary.push(hMtch[i].offsetHeight)
        }
        let max = Math.max(...ary);

        for (let i = 0; i < hMtch.length; i++) {
            hMtch[i].style.height = max + "px"
        }

        // ========================================

        const ary2 = []
        for (let i = 0; i < hmatch2.length; i++) {
            ary.push(hmatch2[i].offsetHeight)
        }
        let max2 = Math.max(...ary2);

        for (let i = 0; i < hmatch2.length; i++) {
            hmatch2[i].style.height = max2 + "px"
        }

    }


    useEffect(() => {
        return HeightMatch()
    }, [listPackage.length ? true : false])
    // match height code start

    const obj = [
        "Icons/Starter.svg",
        "Icons/Essential.svg",
        'Icons/Economy.svg',
        "Icons/Standard.svg",
        "Icons/Premium.svg",
        "Icons/Deluxe.svg",
        "Icons/Ultimate.svg",
        "Icons/luxury.svg",
    ]

    return (
        <>
            <section className="our_packages_banner">
                <div className="container">
                    <div className="our_packages">
                        <h2 className="hd">Our Clubs</h2>
                        <div className="row">
                            {listPackage?.map((item, index) => {
                                return (
                                    <div key={index} className="col-lg-4 col-xl-3 mt-4 h_match2">

                                        <Link to={`/buyNow/${item.id}`}>
                                            <div className="card_box">
                                                <h5 className="d-flex justify-content-between our_pack_head">
                                                    <span className="lear">{item.name}</span>

                                                    <span>
                                                        <img src={obj[index]} alt="" className="img-fluid" width={30} />
                                                    </span>
                                                </h5>

                                                <div className="h_match mb-2" dangerouslySetInnerHTML={{ __html: item.short_description }} style={{ color: "white" }}></div>

                                                <div className="card_footer">
                                                    <div className="row">
                                                        <div className="col-lg-12 col-xl-7 my-2 my-lg-0 btn_w order-2 order-xl-1">
                                                            <Link to={`/buyNow/${item.id}`} className="btn_uni m-0">
                                                                <div style={{ fontSize: "12px" }}>BUY NOW</div>
                                                            </Link>
                                                        </div>
                                                        <div className="col-lg-12 col-xl-5 mt-lg-2 order-1 order-xl-2">
                                                            <h4>${item.price.split('.')[0]}</h4>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="mb-3">
                                                    <div className='d-flex justify-content-end mt-2'>
                                                        <div className="bonus_nft_cards d-flex align-items-center w-100">

                                                            <div className="bonus_nft_flag w-100">
                                                                <div
                                                                    className="d-flex align-items-center justify-content-between w-100"
                                                                >
                                                                    <div>Remaining Clubs :</div>
                                                                    <b className={true ? "" : "bg-danger"}>{item.remaining}</b>
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="bonus_nft_cards text-end">
                                                    {item.bonus_nft > 0 && (
                                                        <span className="d-inline">{item.bonus_nft} BONUS NFTs</span>
                                                    )}
                                                </div>
                                            </div>
                                        </Link>
                                        {/* </Zoom> */}
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
